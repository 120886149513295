import React from "react";
import classes from "./Settings.module.scss";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import PolicyOutlinedIcon from "@mui/icons-material/PolicyOutlined";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import UserDetails from "../UserDetails/UserDetails";
import ChangePassword from "../ChangePassword/ChangePassword";
import ApiKey from "../APIKey/ApiKey";
import PaymentHistory from "../PaymentHistory/PaymentHistory";
import PaymentIcon from '@mui/icons-material/Payment';

const Settings = () => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.header}>My Profile</div>
        <div className={classes.tabWrap}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "#E1E3E6" }}>
              <TabList
                onChange={handleChange}
                // aria-label="lab API tabs example"
              >
                <Tab
                  icon={<Person2OutlinedIcon />}
                  iconPosition="start"
                  label="User Details"
                  value="1"
                  sx={{
                    textTransform: "capitalize",
                    color: "#686868",
                    display: "flex",
                    alignItems: "center",
                    minHeight: "0px",

                    "& .Mui-selected": {
                      color: "#686868",
                    },
                  }}
                />
                <Tab
                  icon={<PolicyOutlinedIcon />}
                  iconPosition="start"
                  label="Change Password"
                  value="2"
                  sx={{
                    textTransform: "capitalize",
                    color: "#686868",
                    display: "flex",
                    alignItems: "center",
                    minHeight: "0px",

                    "& .Mui-selected": {
                      color: "#686868",
                    },
                  }}
                />
                <Tab
                  icon={<InsertLinkOutlinedIcon />}
                  iconPosition="start"
                  label="Api Key"
                  value="3"
                  sx={{
                    textTransform: "capitalize",
                    color: "#686868",
                    display: "flex",
                    alignItems: "center",
                    minHeight: "0px",

                    "& .Mui-selected": {
                      color: "#686868",
                    },
                  }}
                />
                <Tab
                  icon={<PaymentIcon />}
                  iconPosition="start"
                  label="Payment history"
                  value="4"
                  sx={{
                    textTransform: "capitalize",
                    color: "#686868",
                    display: "flex",
                    alignItems: "center",
                    minHeight: "0px",

                    "& .Mui-selected": {
                      color: "#686868",
                    },
                  }}
                />
              </TabList>
            </Box>
            <TabPanel
              value="1"
              sx={{
                padding: "0px",
              }}
            >
              <UserDetails />
            </TabPanel>
            <TabPanel
              value="2"
              sx={{
                padding: "0px",
              }}
            >
              <ChangePassword />
            </TabPanel>
            <TabPanel
              value="3"
              sx={{
                padding: "0px",
              }}
            >
              <ApiKey />
            </TabPanel>
            <TabPanel
              value="4"
              sx={{
                padding: "0px",
              }}
            >
              <PaymentHistory />
            </TabPanel>
          </TabContext>
        </div>
      </div>
    </>
  );
};

export default Settings;
