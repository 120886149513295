import React, { useEffect, useRef, useState } from "react";
import classes from "./Billing.module.scss";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import ClearIcon from "@mui/icons-material/Clear";
import {
  GetPaymentStatusAPICall,
  GetPaymentStatusAPICall2,
  amountDetailsAPIcall,
  paymenyInitialization,
} from "../../Utils/Apihelper";
import { useDispatch, useSelector } from "react-redux";
import ShieldIcon from "@mui/icons-material/Shield";
import { GST } from "../../Utils/constants";
import { useNavigate } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";

const BillingDetails = () => {
  const [loading, setloading] = useState(false);
  const [billdata, setbilldata] = useState<any>({});
  const planName = sessionStorage.getItem("planName");
  const navigate = useNavigate();
  const duration = sessionStorage.getItem("duration");
  const [MID, setMID] = useState("");

  const CountDownEnd = useSelector((state: any) => state.project.countDownEnd);
  let childWindow: any = null;

  useEffect(() => {
    const req = {
      services: "email",
      duration: duration,
      planName: planName,
    };
    amountDetailsAPIcall(req)
      .then((res: any) => {
        setbilldata(res.data.data);
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
  }, []);

  const checkStatus = () => {
    const obj = {
      merchantTransactionId: sessionStorage.getItem("MID"),
      planName: planName,
      planType: duration,
    };

    GetPaymentStatusAPICall2(obj)
      .then((response: any) => {
        const responseData = response.data;
      })
      .catch((error: any) => {
        console.log("hi");
      });
  };

  const handleCheckout = () => {
    setloading(true);
    const req = {
      plan: planName,
      planType: duration,
      gst: GST,
    };
    paymenyInitialization(req)
      .then((res: any) => {
        console.log(res.data);
        const refLink = res.data.data.instrumentResponse.redirectInfo.url;
        sessionStorage.setItem("MID", res.data?.data?.merchantTransactionId);
        childWindow = window.open(refLink, "_blank", "width=1200,height=700");
        const interval = setInterval(() => {
          if (childWindow && !childWindow.closed) {
            const childURL = childWindow.location.href;
            if (
              childURL.includes("phonepe/success") ||
              childURL.includes("phonepe/failed")
            ) {
              setTimeout(() => {
                childWindow.close();
                setloading(false);
                checkStatus();
                navigate("/email-dashboard/account");
              }, 4000);

              clearInterval(interval);
              setloading(false);
            }
          } else {
            clearInterval(interval);
            setloading(false);
            checkStatus();
            toast.error("Payment Failed!", {
              position: "top-right",
              duration: 6000,
            });
          }
        }, 500);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        setloading(false);
      });
  };

  // useEffect(() => {
  //   if (!closetTimer && childWindowRef) {
  //     timerRef.current = setInterval(checkChild, 500);
  //   }
  //   return () => {
  //     clearInterval(timerRef.current);
  //   };
  // }, [childWindowRef, closetTimer]);

  // function checkChild() {
  //   if (childWindowRef && childWindowRef.closed) {
  //     clearInterval(timerRef.current);

  //     const queryParams = new URLSearchParams(window.location.search);
  //     const config = queryParams.get("token");
  //     const Id = queryParams.get("merchantTransactionId");

  //     const obj = {
  //       merchantTransactionId: Id,
  //     };

  //     GetPaymentStatusAPICall(obj, config)
  //       .then((response: any) => {
  //         const responseData = response.data;
  //         setTimeout(() => {
  //           setloading(false);
  //         }, 2300);
  //       })
  //       .catch((error: any) => {
  //         setTimeout(() => {
  //           setloading(false);
  //         }, 2300);
  //       });
  //   }
  // }

  return (
    <>
      <div className={classes.Billingwrapper}>
        <div className={classes.header}>Payment Details</div>
        <div>
          <Grid container spacing={0}>
            <Grid item xs={6} md={6}>
              <div className={classes.wrap}>
                <div className={classes.dataBlock1}>
                  <span className={classes.listTitle}> Billing Name: </span>
                  {billdata?.billing_details?.billingName}
                </div>
              </div>
            </Grid>
            <Grid item xs={6} md={6}>
              <div className={classes.wrap}>
                <div className={classes.dataBlock1}>
                  <span className={classes.listTitle}>GST Number: </span>{" "}
                  {billdata?.billing_details?.GST === ""
                    ? ""
                    : billdata?.billing_details?.GST}
                </div>
              </div>
            </Grid>
            <Grid item xs={6} md={6}>
              <div className={classes.wrap}>
                <div className={classes.dataBlock1}>
                  <span className={classes.listTitle}> Plan Name :</span>{" "}
                  {billdata?.billing_details?.planName}
                </div>
              </div>
            </Grid>
            <Grid item xs={6} md={6}>
              <div className={classes.wrap}>
                <div className={classes.dataBlock1}>
                  <span className={classes.listTitle}> Expiry Date :</span>{" "}
                  {billdata?.billing_details?.expiry}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>

        <div className={classes.amount}>
          <Grid container spacing={0}>
            <Grid item xs={6} md={4}>
              <div className={classes.wrapH}>
                <div className={classes.dataBlock3}>Name</div>
              </div>
            </Grid>
            <Grid item xs={6} md={2}>
              <div className={classes.wrapH}>
                <div className={classes.dataBlock3}>Service</div>
              </div>
            </Grid>
            <Grid item xs={6} md={2}>
              <div className={classes.wrapH}>
                <div className={classes.dataBlock3}>Duration</div>
              </div>
            </Grid>
            <Grid item xs={6} md={4}>
              <div className={classes.wrapH}>
                <div className={classes.dataBlock3}>Price</div>
              </div>
            </Grid>
          </Grid>

          <div>
            <Grid container spacing={0}>
              <Grid item xs={6} md={4}>
                <div className={classes.wrap}>
                  <div className={classes.dataBlock1}>
                    {billdata?.product_details?.name}
                  </div>
                </div>
              </Grid>
              <Grid item xs={6} md={2}>
                <div className={classes.wrap}>
                  <div className={classes.dataBlock1}>
                    {billdata?.product_details?.service}
                  </div>
                </div>
              </Grid>
              <Grid item xs={6} md={2}>
                <div className={classes.wrap}>
                  <div className={classes.dataBlock1}>
                    {billdata?.product_details?.duration}
                  </div>
                </div>
              </Grid>
              <Grid item xs={6} md={4}>
                <div className={classes.wrap}>
                  <div className={classes.dataBlock1}>
                    {billdata?.product_details?.price}
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={6} md={8}>
                <div className={classes.wrap}>
                  <div className={classes.dataBlock2}>SGST</div>
                </div>
              </Grid>
              <Grid item xs={6} md={4}>
                <div className={classes.wrap}>
                  <div className={classes.dataBlock1}>
                    {billdata?.amount_details?.sgst}
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={6} md={8}>
                <div className={classes.wrap}>
                  <div className={classes.dataBlock2}>CGST</div>
                </div>
              </Grid>
              <Grid item xs={6} md={4}>
                <div className={classes.wrap}>
                  <div className={classes.dataBlock1}>
                    {billdata?.amount_details?.cgst}
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={6} md={8}>
                <div className={classes.wrap}>
                  <div className={classes.dataBlock2}>GST Total</div>
                </div>
              </Grid>
              <Grid item xs={6} md={4}>
                <div className={classes.wrap}>
                  <div className={classes.dataBlock1}>
                    {billdata?.amount_details?.total_gst}
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={6} md={8}>
                <div className={classes.wrap}>
                  <div className={classes.dataBlock2}>Total Amount</div>
                </div>
              </Grid>
              <Grid item xs={6} md={4}>
                <div className={classes.wrap}>
                  <div className={classes.dataBlock1}>
                    {billdata?.amount_details?.total_amount}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={classes.note}>
          By placing this secure order, I agree to the Privacy Policy and the
          Subscription and Cancellation Terms (For subscription products only).
        </div>
        <div className={classes.continue} onClick={handleCheckout}>
          {loading ? (
            <div className={classes.loader}></div>
          ) : (
            <>
              <ShieldIcon fontSize="small" /> Secure Checkout
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default BillingDetails;
