import React from "react";
import { Routes, Route, Navigate, useRoutes } from "react-router-dom";
import Register from "../../containers/Register/Register";
import Login from "../../containers/Login/Login";
import ForgotPassword from "../../containers/ForgotPassword/ForgotPassword";
import OtpScreen from "../../containers/OtpScreen/OtpScreen";
import ChangePassword from "../../containers/ChangePassword/ChangePassword";
import SuccessModal from "../SuccessModal/SuccessModal";
// import Dashboard from "../../containers/dashboard/dashboard";
import RegisterOtp from "../../containers/Register/RegisterOtp/RegisterOtp";
import SubscriberInfo from "../../containers/SubscriberInfo/SubscriberInfo";
import GeneralBoard from "../../containers/GeneralBoard/GeneralBoard";
import Campaigns from "../../containers/Campaigns/Campaigns";
import SubscribersList from "../../containers/SubscribersList/SubscribersList";
import Template from "../../containers/Template/Template";
import { useSelector } from "react-redux";
import CampaignDetails from "../../containers/CampaignDetails/CampaignDetails";
import AllTemplates from "../../containers/AllTemplates/AllTemplates";
import Unsubscribe from "../../containers/Unsubscribe/Unsubscribe";
import EditTemplate from "../../containers/EditTemplate/EditTemplate";
import Sms from "../../containers/Sms/Sms";
import PricingPage from "../../containers/Pricing/PricingPage";
import TermsPrivacy from "../Pages/TermsandConditions/TermsandPrivacy";
import ReturnPolicy from "../Pages/ReturnPolicy/ReturnPolicy";
import Privacy from "../Pages/Privacy/Privacy";
import LandingPage from "../../containers/LandingPage/LandingPage";
import EmailTemplateEditor from "../../containers/EmailTemplateEditor/EmailTemplateEditor";
import AWSConfiguration from "../Pages/AWSConfiguration/AWSConfiguration";
import Support from "../Pages/Support/Support";
import Settings from "../Pages/Settings/Settings";
import APIDocs from "../Pages/APIDocs/APIDocs";
import Scheduling from "../Pages/Scheduling/Scheduling";
import Subscribers from "../../containers/Subscribers/Subscribers";
import CampaignDetailsView from "../../containers/CampaignDetailsView/CampaignDetailsView";
import Campaignstatistics from "../../containers/CampaignStatistics/Campaignstatistics";
import EmailsList from "../../containers/EmailsList/EmailsList";
import CreateCamp from "../../containers/CreateCamp/CreateCamp";
import ImportCsv from "../../containers/ImportCSV/ImportCsv";
import Pricing from "../Pages/Pricing/Pricing";
import EditCred from "../../containers/EditCred/EditCred";
import WtspLandingPage from "../../containers/Whatsapp/WtspLandingPage";
import EmailReports from "../../containers/EmailReports/EmailReports";
import Dashboard from "../../containers/Dashboard/Dashboard";
import WPDashboard from "../../containers/Whatsapp/WPDashboard/WPDashboard";
import MainLandingHeroPage from "../../containers/MainlandingPage/MainLandingHeroPage/MainLandingHeroPage";
import ContactUs from "../../containers/ContactUs/ContactUs";
import MainPricingPage from "../../containers/MainlandingPage/MainPricingPage/MainPricingPage";
import BillingInput from "../../containers/Billing/BillingInput";
import BillingDetails from "../../containers/Billing/BillingDetails";
import PhonepaySuccess from "../Pages/Phonepay/Phonepay";
import Phonepay from "../Pages/Phonepay/Phonepay";
import HelpCenter from "../../containers/HelpCenter/HelpCenter";
import PrivacyPolicy from "../Pages/PrivacyPolicy/PrivacyPolicy";

// const Router = () => {
//   return (
//     <div>
//       <Routes>
//         <Route>
//           <Route path="/register" element={<Register />} />
//           <Route path="" element={<Login/>}/>
//           <Route path="/forgotPassword" element={<ForgotPassword/>}/>
//           <Route path="/otpScreen" element={<OtpScreen/>}/>
//           <Route path="/changePassword" element={<ChangePassword/>}/>
//           {/* <Route path="/successModal" element={<SuccessModal/>}/> */}
//           <Route path="/email-dashboard" element={<Dashboard/>}/>
//           <Route path="/RegisterOtp" element={<RegisterOtp/>}/>

//         </Route>
//       </Routes>
//     </div>
//   )
// }
function Router() {
  const subscriber = useSelector((state) => state.project.subscriber);
  const product = sessionStorage.getItem("product");
  const routes = useRoutes([
    {
      path: "",
      element: <MainLandingHeroPage />
    },
    {
      path: "email",
      element: <LandingPage />,
    },
    {
      path: "whatsapp",
      element: <WtspLandingPage />,
    },
    {
      path: "contactus",
      element: <ContactUs />
    },
    {
      path: "pricings",
      element: <MainPricingPage />
    },
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "editCredentials",
      element: <EditCred />,
    },
    {
      path: "unsubscribe",
      element: <Unsubscribe />,
    },
    {
      path: `otpScreen`,
      element: <OtpScreen />,
    },
    // {
    //   path: "pricing",
    //   element: <PricingPage />,
    // },
    
    {
      path: `register`,
      element: <Register />,
    },
    {
      path: `RegisterOtp`,
      element: <RegisterOtp />,
    },
    {
      path: `forgotPassword`,
      element: <ForgotPassword />,
    },

    {
      path: `changePassword`,
      element: <ChangePassword />,
    },
    {
      path: `termsandconditions`,
      element: <TermsPrivacy />,
    },
    {
      path: `helpcenter`,
      element: <HelpCenter />,
    },

    {
      path: `refundpolicy`,
      element: <ReturnPolicy />,
    },
    {
      path: `privacypolicy`,
      element: <PrivacyPolicy />,
    },
    {
      path: `phonepe/success`,
      element: <Phonepay />,
    },
    {
      path: `phonepe/failed`,
      element: <Phonepay />,
    },
    {
      path: "/email-dashboard",
      element: <Dashboard />,
      children: [
        { element: <Navigate to="analytics" />, index: true },
        {
          path: `analytics`,
          element: <GeneralBoard />,
        },
        {
          path: ``,
          children: [
            {
              element: <Navigate to="/email-dashboard/allCampaigns" />,
              index: true,
            },
            {
              path: `emails`,
              element: <EmailsList />,
            },
            {
              path: `emails/create`,
              element: <CreateCamp />,
            },
            {
              path: `transactional`,
              element: <Campaigns />,
            },
            {
              path: `reports`,
              element: <EmailReports logs={false} />,
            },
            {
              path: `aws`,
              element: <AWSConfiguration />,
            },
            {
              path: `scheduling`,
              element: <Scheduling />,
            },
            {
              path: `support`,
              element: <Support />,
            },
            {
              path: `pricing`,
              element: <Pricing />,
            },
            {
              path: "payment",
              element: <BillingInput />,
            },
            {
              path: "billingdetails",
              element: <BillingDetails />,
            },
            {
              path: `account`,
              element: <Settings />,
            },
            {
              path: `apiDocs`,
              element: <APIDocs />,
            },
            {
              path: `emails/campaignDetails`,
              element: <CampaignDetails />,
            },
            {
              path: `emails/campaignDetailsView`,
              element: <CampaignDetailsView />,
            },
            {
              path: `campaignstatistics`,
              element: <Campaignstatistics />,
            },
          
          ],
        },
        {
          path: ``,
          children: [
            {
              element: <Navigate to="/email-dashboard/sms" />,
              index: true,
            },
            {
              path: `sms`,
              element: <Sms />,
            },
          ],
        },
        {
          path: ``,
          children: [
            {
              element: <Navigate to="/email-dashboard/allSubscribers" />,
              index: true,
            },
            {
              path: `subscriberList`,
              element: <Subscribers />,
            },
            {
              path: `subscriberList/import`,
              element: <ImportCsv />,
            },
            {
              path: `subscriberList/subscriberInfo`,
              element: <SubscriberInfo />,
            },
          ],
        },
        {
          path: ``,
          children: [
            {
              element: <Navigate to="/email-dashboard/templates" />,
              index: true,
            },
            {
              path: `templates`,
              element: <Template />,
            },

            {
              path: `allTemplates`,
              element: <AllTemplates />,
            },
            {
              path: `allTemplates/emailTemplateEditor`,
              element: <EmailTemplateEditor />,
            },
            {
              path: `editTemplates`,
              element: <EditTemplate />,
            },
          ],
        },
      ],
    },
    {
      path: "/sms-dashboard",
      element: <Dashboard />,
      children: [
        { element: <Navigate to="analytics" />, index: true },
        {
          path: `analytics`,
          element: <WPDashboard />,
        },
      ],
    },
    {
      path: "/clients",
      // element: <DashboardLayout />,
    },
  ]);
  return routes;
}

export default Router;
